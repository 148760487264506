let gr = 0
function classifyNode(node) {
  if (node.startsWith('point_'))
    return {type: 'point', node, id: node.replace('point_', '')}
  if (node.startsWith('before_'))
    return {type: 'before', node, id: node.replace('before_', '')}
  if (node.startsWith('after_'))
    return {type: 'after', node, id: node.replace('after_', '')}
  if (isFinite(parseInt(node, 36))) return {type: 'int', node, id: node}
  throw new Error(`unknown node ${node}`)
}
window.processGraph = function processGraph(graph) {
  const nodes = Object.keys(graph)
  const classified = nodes.map(classifyNode)
  const baseNodes = classified.map(({node, type, id}) => ({
    id: node,
    group: parseInt(id, 36) * (type === 'int' ? 0 : 1),
    label: [type[0], id].join(' '),
    level: type === 'int' ? (id === '-1' ? 1 : 2) : type === 'before' ? 1 : 2,
  }))
  const baseLinks = []
  for (const cl of classified) {
    const source = cl.node
    for (const target of graph[source]) {
      const clt = classifyNode(target)
      let strength
      if (clt.id === cl.id) {
        strength = 0.7
      } else if (cl.type === 'int') {
        strength = 0.5
      } else {
        strength = 0.3
      }
      baseLinks.push({source, target, strength})
    }
  }
  return {baseNodes, baseLinks}
}
